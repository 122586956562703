import store from "@/store"; // Vuex 스토어 가져오기

// Lock 함수: Daily Check 중 시스템 잠금
async function lock() {
 // try {
    // 1. Vuex에서 isLocked 활성화
    store.commit("SET_LOCK", true);
    console.log("System locked for Daily Check.");

    // 2. 백엔드와 동기화 (선택 사항)
//     await fetch("/api/lock", {
//       method: "POST",
//       headers: { "Content-Type": "application/json" },
//       body: JSON.stringify({ isLocked: true }),
//     });
//     console.log("Lock state synced with backend.");
//   } catch (error) {
//      console.error("Error during lock operation:", error);
//    }
}

// Unlock 함수: Daily Check 종료 후 잠금 해제
async function unlock() {
//  try {
    // 1. Vuex에서 isLocked 비활성화
    store.commit("SET_LOCK", false);
    console.log("System unlocked after Daily Check.");

    // 2. 백엔드와 동기화 (선택 사항)
//     await fetch("/api/lock", {
//       method: "POST",
//       headers: { "Content-Type": "application/json" },
//       body: JSON.stringify({ isLocked: false }),
//     });
//     console.log("Unlock state synced with backend.");
//   } catch (error) {
//     console.error("Error during unlock operation:", error);
//   }
}

export { lock, unlock };
