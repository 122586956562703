import { readFromLocalStorage } from "@/lib/LocalStorageHelper";
import { lock,unlock } from "@/lib/LockManager";
import axios from "@/axios";
import store from "@/store";

//마지막 체크한 날자 비교로직
const DailyCheckModule = {
  // Check if daily update is needed based on lastCheckedDate
  checkLastCheckedDate() {
    const today = new Date().toISOString().split("T")[0]; // YYYY-MM-DD
    const storedData = readFromLocalStorage('smsData');

    if (!storedData || storedData.lastCheckedDate !== today) {
      console.log("Date mismatch or data not found. Performing daily check...");      
      return true; // Trigger daily check
    }

    console.log("Date matches. Skipping daily check...");
    return false; // Skip daily check
  },

  async fetchCounts() {
    try {

      const tokenData = JSON.parse(sessionStorage.getItem("token"));
      const token = tokenData ? tokenData.access_token : "";
      const response = await axios.get("/sms/common/counts", {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });      
      
      const { calculatedCount, currentCount } = response.data;
      console.log("Fetched counts:", { calculatedCount, currentCount });
      return { calculatedCount, currentCount };
    } catch (error) {
      console.error("Failed to fetch counts:", error);
      throw error;
    }
  },

  async performDailyCheck() {
    await lock(); // Lock 적용
    try {
      const { calculatedCount, currentCount } = await this.fetchCounts();

      console.log(`Calculated Count: ${calculatedCount}, Current Count: ${currentCount}`);
      
      const today = new Date().toISOString().split("T")[0];

      // Compare counts
      if (calculatedCount !== currentCount) {
        console.log("Mismatch detected. Recreating SMS data...");

         await this.recreateSmsData("01012345678"); // Recreate SMS data

        const updatedData = {
          lastCheckedDate: today,
          expectedCount: calculatedCount,
          currentCount: calculatedCount, // Assume data is consistent post-recreation
        };

        store.commit("SET_SMS_DATA", updatedData); // Vuex 업데이트
        console.log("Vuex and sessionStorage updated with new SMS data.");
        return updatedData;
      } else {
        console.log("No mismatch. Data is consistent.");
        
        // Update lastCheckedDate only
        const updatedData = {
          lastCheckedDate: today,
          expectedCount: calculatedCount,
          currentCount: currentCount,
        };

        store.commit("SET_SMS_DATA", updatedData); // Vuex 업데이트
        console.log("LastCheckedDate updated in Vuex and sessionStorage.");
        return updatedData;
      }
    } finally {
      await unlock(); // Lock 해제
    }
  },


  async  recreateSmsData(sendPhone) {
    try {
      console.log("Recreating SMS data for consistency...");
  
      // 로컬 스토리지에서 토큰 정보 가져오기
      const tokenData = JSON.parse(sessionStorage.getItem("token"));
      const token = tokenData ? tokenData.access_token : "";
  
      if (!sendPhone) {
        throw new Error("sendPhone is required to recreate SMS data.");
      }
  
      // 백엔드 API 호출
      const response = await axios.post(
        "/sms/common/recreate",
        { sendPhone },
        {
          headers: {
            Authorization: `Bearer ${token}`, // 토큰 추가
          },
        }
      );
  
      if (response.status === 200 || response.status === 201) {
        console.log("SMS data recreation completed successfully:", response.data);
      } else {
          console.error("Unexpected response status:", response.status);
      }
    } catch (error) {
      console.error("Failed to recreate SMS data:", error.message || error);
    }

  },
 

};

export default DailyCheckModule;
