<template>
  <div class="main-container">
    <h5 class="pageTitle">발송 주기 등록</h5>

    <!-- 등록 폼 -->
    <div class="form-container">
      <!-- 기체관리명 -->
      <div class="form-group">
        <label for="item">기체관리명</label>
        <select v-model="formData.item" class="input-box" @change="handleItemChange">
          <option value="0">보험</option>
          <option value="1">기체 안정성</option>
          <option value="2">비행 승인</option>
        </select>
      </div>

       <!-- 템플릿명과 템플릿 내용 -->
       <div class="form-group">
        <label for="template">템플릿명</label>
        <select v-model="formData.template_ID" class="input-box">
          <option v-for="template in templates" :key="template.uuid" :value="template.uuid">
            {{ template.msg_name }}
          </option>
        </select>
      </div>
      <div class="form-group">
        <label for="templateContent">템플릿 내용</label>
        <textarea class="input-box wide-input" readonly v-model="selectedTemplateContent"></textarea>
      </div>

      <!-- 발송일 -->
      <div class="form-group">
        <label for="sendPeriod">발송일</label>
        <div class="dropdown-container">
          <div class="dropdown-display" @click="toggleDropdown">
            {{ selectedPeriods.join(', ') || '발송일을 선택하세요.' }}
          </div>
          <div class="dropdown-list" v-if="isDropdownOpen">
            <div v-for="period in sendPeriods" :key="period.value" class="dropdown-item"
            @click="toggleCheckbox(period.value, $event)">
              <input
                type="checkbox"
                :id="period.value"
                :value="parseInt(period.value)"
                v-model="formData.send_period"
                @change="checkSendPeriodLimit"
                
              />
              <label :for="period.value">{{ period.label }}</label>
            </div>
          </div>
        </div>
      </div>
<!-- :disabled="formData.send_period.length >= 5 && !formData.send_period.includes(period.label)" -->
      <!-- 발송시간 -->
      <div class="form-group">
        <label for="sendTime">발송시간</label>
        <input v-model="formData.send_time" type="time" class="input-box" />
      </div>

      <!-- 버튼 그룹 -->
      <div class="button-container">
        <button class="button-list" @click="goToList">목록</button>
        <button class="button-register" @click="validateAndRegister">등록</button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "@/axios";
import Swal from "sweetalert2";
import DailyCheckModule from "@/lib/DailyCheckModule";

export default {
  // props: {
  //   existingPeriods: {
  //     type: Array,
  //     default: () => [],
  //   },
  // },
  data() {
    return {    
      maxSendPeriods: Number(process.env.VUE_APP_MAX_SEND_PERIODS) || 5,
      formData: {
        item: "",
        template_ID: "",
        send_period: [],
        send_time: "10:00",
      },
      templates: [],
      sendPeriods: [
        { value: "1", label:  "1일전" },
        { value: "2", label:  "2일전" },
        { value: "3", label:  "3일전" },
        { value: "4", label:  "4일전" },
        { value: "5", label:  "5일전" },
        { value: "6", label:  "6일전" },
        { value: "7", label:  "7일전" },
        { value: "8", label:  "8일전" },
        { value: "9", label:  "9일전" },
        { value: "10", label: "10일전" },
        { value: "11", label: "11일전" },
        { value: "12", label: "12일전" },
        { value: "13", label: "13일전" },
        { value: "14", label: "14일전" },
        { value: "15", label: "15일전" },
        { value: "16", label: "16일전" },
        { value: "17", label: "17일전" },
        { value: "18", label: "18일전" },
        { value: "19", label: "19일전" },
        { value: "20", label: "20일전" },
        { value: "21", label: "21일전" },
        { value: "22", label: "22일전" },
        { value: "23", label: "23일전" },
        { value: "24", label: "24일전" },
        { value: "25", label: "25일전" },
        { value: "26", label: "26일전" },
        { value: "27", label: "27일전" },
        { value: "28", label: "28일전" },
        { value: "29", label: "29일전" },
        { value: "30", label: "30일전" },
        { value: "31", label: "31일전" },
        { value: "60", label: "60일전" },
        { value: "90", label: "90일전" },
      ],
      isDropdownOpen: false,
      disabledPeriods: [],
      existingPeriods:[],
    };
  },
  computed: {
   
    senderNumber() {
      return this.$store.state.senderNumber; // Vuex state에서 발신 번호 가져오기
    },
    selectedTemplateContent() {
      const selectedTemplate = this.templates.find(
        (template) => template.uuid === this.formData.template_ID
      );
      return selectedTemplate ? selectedTemplate.msg_contents : "";
    },
    selectedPeriods() {
      // 기존 등록된 발송일과 선택된 발송일을 병합
      return [
        ...new Set([...this.existingPeriods.map((p) => p.send_period), ...this.formData.send_period]),
      ].sort((a, b) => a - b);
    },
    // selectedPeriods() {
    //   return this.formData.send_period;
    // },
  },
  methods: {
    handleItemChange() {
      if (this.formData.item) {
        //console.log("Item Changed!!!!!");
        this.formData.send_period=[];
        this.fetchExistingPeriods(this.formData.item); // 백엔드 호출
      }
    },
    toggleCheckbox(value, event) {
      event.preventDefault(); // 기본 동작 방지
      event.stopPropagation(); // 이벤트 전파 중단

      // 선택된 값이 이미 있는 경우 제거
      if (this.formData.send_period.includes(value)) {
        this.formData.send_period = this.formData.send_period.filter((v) => v !== value);
      } else {
        // 총 5개를 초과하면 추가 금지
        const totalPeriodsCount = new Set([
          ...this.existingPeriods.map((p) => p.send_period),
          ...this.formData.send_period,
          value,
        ]).size;

        if (totalPeriodsCount > this.maxSendPeriods) {
          Swal.fire({
            icon: "error",
            title: "선택 제한",
            text: `발송일은 최대 ${this.maxSendPeriods}개까지만 선택 가능합니다.`,
          });
          return;
        }

        // 선택된 값 추가
        this.formData.send_period.push(value);
      }
    },
  
    checkSendPeriodLimit() {
    // 기존 등록된 발송일과 합쳐서 5개 초과 여부 체크
    const totalPeriodsCount = new Set([
      ...this.existingPeriods.map((p) => p.send_period),
      ...this.formData.send_period,
    ]).size;

    if (totalPeriodsCount > this.maxSendPeriods) {
      Swal.fire({
        icon: "error",
        title: "선택 제한",
        text: `발송일은 최대 ${this.maxSendPeriods}개까지만 선택 가능합니다.`,
      });

      // formData에서 마지막 추가된 항목 제거
      this.formData.send_period.pop();
    }
  },
    toggleDropdown() {
      this.isDropdownOpen = !this.isDropdownOpen;
    },
    async fetchTemplates() {
      try {
        const tokenData = JSON.parse(sessionStorage.getItem("token"));
        const token = tokenData ? tokenData.access_token : "";
        const response = await axios.get("/sms-period/read_templates",{
          headers: {
                Authorization: `Bearer ${token}`,
              },
        });
        this.templates = response.data;
      } catch (error) {
        console.error("템플릿 데이터 조회 실패:", error);
      }
    },
    goToList() {
      this.$router.push({ name: "sms-period-read" });
    },

    async fetchExistingPeriods(item) {
      try {
        const tokenData = JSON.parse(sessionStorage.getItem("token"));
        const token = tokenData ? tokenData.access_token : "";

        const response = await axios.get(`/sms-period/read_existing_periods`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: { item }, // 선택한 아이템 전달
        });

        this.existingPeriods = response.data; // 백엔드에서 받은 기존 데이터를 저장
        console.log("가능 갯수: "+(this.maxSendPeriods-this.existingPeriods.length));
      } catch (error) {
        console.error("기존 발송 주기 데이터 조회 실패:", error);
        Swal.fire({
          icon: "error",
          title: "데이터 조회 실패",
          text: "기존 데이터 조회에 실패했습니다. 다시 시도해주세요.",
        });
      }
    },

    validateAndRegister() {
      console.log("Form data item:", this.formData.item);
      console.log("Existing periods:", this.existingPeriods);
      
      if (!this.formData.item || !this.formData.template_ID|| !this.formData.send_time|| !this.formData.send_period || this.formData.send_period.length === 0) {
          Swal.fire({
              icon: "error",
              title: "등록 실패",
              text: "모든 필드를 입력해야 합니다.",
          });
          return;
      }
      
      const filteredPeriods = this.existingPeriods.filter(
          (period) => String(period.item) === String(this.formData.item)
      );

      console.log("Filtered periods after filtering:", filteredPeriods);

      const isDuplicate = this.formData.send_period.some((newPeriod) =>
        filteredPeriods.some((period) => Number(period.send_period) === Number(newPeriod))
      );

      if (isDuplicate) {
          Swal.fire({
              icon: "error",
              title: "등록 실패",
              text: "동일한 발송일이 이미 등록되어 있습니다.",
          });
          return;
      }
      // 선택된 주기 갯수
      // const selectedPeriodsCount = this.formData.send_period.length;
      // if (filteredPeriods.length >= 5|| (filteredPeriods.length + selectedPeriodsCount)>5) {
      //     Swal.fire({
      //         icon: "error",
      //         title: "등록 실패",
      //         text: "5개 이상 등록할 수 없습니다.",
      //     });
      //     return;
      // }
      
       // 기존 데이터와 신규 데이터 합계 체크
      //const newPeriodsCount = this.formData.send_period.length;
      //const totalPeriodsCount = this.existingPeriods.length + newPeriodsCount;
      const totalPeriodsCount = new Set([
        ...this.existingPeriods.map((p) => p.send_period),
        ...this.formData.send_period,
      ]).size;
      const availableCount = this.maxSendPeriods-this.existingPeriods.length;
      
      if (totalPeriodsCount > this.maxSendPeriods) {
        Swal.fire({
          icon: "error",
          title: "등록 실패",
          text: `발송일은 ${availableCount}개까지만 선택 가능합니다.`,
        });

        // 초과된 발송일 제거
        this.formData.send_period = this.formData.send_period.slice(0, this.maxSendPeriods - this.existingPeriods.length);
        return;
      }

      this.register()
      .then(() => {
              // 등록 성공 후 로컬 상태 업데이트
              this.formData.send_period.forEach((sendPeriod) => {
                  this.existingPeriods.push({
                      item: this.formData.item,
                      send_period: sendPeriod,
                  });
              });
          })
          .catch((error) => {
              Swal.fire({
                  icon: "error",
                  title: "등록 실패",
                  text: error.message || "등록에 실패했습니다.",
              });
      });
    },


    async register() {
      try {
        const tokenData = JSON.parse(sessionStorage.getItem("token"));
        const token = tokenData ? tokenData.access_token : "";

        // 기존 등록된 데이터를 필터링하여 신규 데이터만 추출
        const newSendPeriods = this.formData.send_period.filter(
          (period) => !this.existingPeriods.some((existing) => existing.send_period === period)
          )
          .map((period)=>Number(period));

        const config = {
          method: "post",
          url: "/sms-period/create_period",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          data: {
            ...this.formData,
            send_period: newSendPeriods,
          }
        };

        await axios(config);
        
        await DailyCheckModule.recreateSmsData(this.senderNumber);

        Swal.fire({
          icon: "success",
          title: "등록 성공",
          text: "발송 주기가 등록되었습니다.",
        });
        this.goToList();
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "등록 실패",
          text: "등록 중 문제가 발생했습니다.",
        });
      }
    },

    initializeDisabledPeriods() {
      this.disabledPeriods = this.existingPeriods.map(
        (item) => Number(item.send_period)
      );
    },
  },

  mounted() {
    this.fetchTemplates();
    // if (this.existingPeriods && this.existingPeriods.length > 0) {
    //   console.log("라우터로부터 받은 데이터:", this.existingPeriods);
    // } else {
    //   console.warn("라우터로부터 데이터가 전달되지 않았습니다.");
    // }
  },
};
</script>

  
  <style scoped>
  .main-container {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .pageTitle {
    font-weight: bold;
    margin-bottom: 40px;
    text-align: left;
    width: 100%;
  }
  
  .form-container {
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 70%;
    max-width: 800px;
  }
  
  .form-group {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    /* gap: 10px;
    flex-wrap: nowrap; */
    width: 100%;
  }
  
  .input-box {
    flex: 1;
    padding: 8px;
    margin-right: 40px;
    border: 1px solid rgba(130, 128, 128, 0.26);
    border-radius: 3px;
    max-width: 200px;
  }
  
  .checkbox-container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }
  
  .checkbox-item {
    display: flex;
    align-items: center;
  }
  
  .checkbox-item input {
    margin-right: 5px;
  }
  
  .template-content {
    margin-top: 5px;
    font-size: 0.9em;
    color: #666;
  }
  
  .button-container {
    margin-top: 60px;
    display: flex;
    gap: 30px;
  }
  
  .button-list {
    background-color: #ff9800;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 3px;
    cursor: pointer;
  }
  
  .button-register {
    background-color: #4caf50;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 3px;
    cursor: pointer;
  }
  .button-list:hover {
    background-color: #e68900;
  }

  .button-register:hover {
    background-color: #3e8e41;
  }

  .dropdown-container {
    position: relative;
    display: inline-block;
  }

  .dropdown-display {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 3px;
    cursor: pointer;
    min-width: 300px; /* 최소 너비 고정 */
    height: 40px; /* 고정 높이 */
    overflow: hidden; /* 텍스트가 길 경우 ... 처리 */
    white-space: nowrap; /* 한 줄로 처리 */
    text-overflow: ellipsis; /* 넘치는 텍스트를 ...으로 표시 */
  }

  .dropdown-list {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    z-index: 1000;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 3px;
    max-height: 200px;
    overflow-y: auto;
  }

  .dropdown-item {
    padding: 8px;
    display: flex;
    align-items: center;
  }

  .dropdown-item input {
    margin-right: 5px;
  }
  .template-group {
    display: flex;
    gap: 20px;
  }
  .wide-input {
    min-width: 400px;
  }
  </style>
  